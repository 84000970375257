<template>
 <TaskListWrap class="mb-30">
  <div class="sDash_tasklist-wrap">
   <div class="sDash_tasklist-head">
    <h2 class="sDash_task-list-title">Task Lists</h2>
   </div>
   <div class="sDash_tasklist-body">
    <ul v-if="task.length > 0" class="sDash_tasks">
     <li v-for="(item, i) in task" :key="i" class="sDash_tasks-item">
      <div class="sDash_tasks-item__content">
       <div class="sDash_tasks-item__title">
        <a-checkbox
         :checked="!!item.completed"
         @change="() => dispatch('onCompleteUpdate', { data: task, id: item.id })"
        >
         {{ item.title }}
        </a-checkbox>
       </div>
       <div class="sDash_tasks-item__text">
        <p>{{ item.description }}</p>
       </div>
      </div>

      <div class="sDash_tasks-item__action">
       <a
        :class="`${item.favourite ? 'task-favourite active' : 'task-favourite'}`"
        to="#"
        @click="() => dispatch('onStarUpdate', { data: task, id: item.id })"
       >
        <sdFeatherIcons size="14" type="flag"/>
       </a>
       <sdDropdown>
        <template #overlay>
         <a to="#" @click="() => showModal(item.id, item)">
          <sdFeatherIcons size="14" type="edit"/>
          <span>Edit</span>
         </a>
         <a to="#" @click="() => handleTaskDelete(item.id)">
          <sdFeatherIcons size="14" type="trash-2"/>
          <span>Delete</span>
         </a>
        </template>
        <a to="#">
         <sdFeatherIcons size="18" type="more-vertical"/>
        </a>
       </sdDropdown>
      </div>
     </li>
    </ul>

    <div v-else class="sDash_empty-task">
     <a-empty :image="simpleImage">
      <template #description>
         <span>
           Geen taken beschikbaar
         </span>
      </template>
     </a-empty>
    </div>
   </div>
  </div>
  <sdModal
   :footer="null"
   :onCancel="handleCancel"
   :visible="visible"
   class="sDash_addTask-modal"
   title="Edit Task"
   type="primary"
  >
   <div class="sDash_addTask-modal-inner">
    <BasicFormWrapper>
     <a-form :model="formState" name="add-task" @finish="onEditHandle">
      <a-form-item name="title">
       <a-input v-model:value="formState.title" placeholder="Title"/>
      </a-form-item>

      <a-form-item name="description">
       <a-textarea v-model:value="formState.description" :rows="4" placeholder="Add Description"/>
      </a-form-item>
      <a-form-item hidden="true" name="favourite">
       <a-input v-model:value="formState.favourite"/>
      </a-form-item>
      <a-form-item hidden="true" name="completed">
       <a-input v-model:value="formState.completed"/>
      </a-form-item>

      <div class="sDash-modal-actions">
       <sdButton key="cancel" outlined size="small" type="white" @click="handleCancel"> Cancel</sdButton>
       <sdButton key="submit" htmlType="submit" size="small" type="primary"> Submit</sdButton>
      </div>
     </a-form>
    </BasicFormWrapper>
   </div>
  </sdModal>
 </TaskListWrap>
</template>
<script>
import {computed, reactive, ref} from 'vue';
import {useStore} from 'vuex';
import {BasicFormWrapper} from '../../styled';
import {TaskListWrap} from '../style';

const All = {
 name: 'All',
 components: {BasicFormWrapper, TaskListWrap},
 setup() {
  const {state, dispatch} = useStore();
  const task = computed(() => state.task.data.sort((a, b) => b.id - a.id));
  const visible = ref(false);
  const taskEditId = ref('');
  const editableItem = ref({});

  let formState = reactive({
   title: '',
   description: '',
   favourite: '',
   completed: '',
   id: '',
  });

  const showModal = (id, item) => {
   taskEditId.value = id;
   visible.value = true;
   editableItem.value = item;
   formState.title = item.title;
   formState.description = item.description;
   formState.favourite = item.favourite;
   formState.completed = item.completed;
   formState.id = id;
  };

  const handleCancel = () => {
   visible.value = false;
  };

  const handleTaskDelete = (id) => {
   const value = task.value.filter((item) => item.id !== id);
   dispatch('ontaskDelete', value);
  };

  const onEditHandle = (data) => {
   const updatedData = task.value.map((item) => {
    if (item.id === formState.id) {
     // eslint-disable-next-line no-param-reassign
     item = data;
     return item;
    }
    return item;
   });

   visible.value = false;
   dispatch('ontaskEdit', updatedData);
  };

  return {
   task,
   visible,
   taskEditId,
   editableItem,
   showModal,
   handleCancel,
   handleTaskDelete,
   onEditHandle,
   dispatch,
   formState,
  };
 },
};

export default All;
</script>
